<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">تعديل المورد</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <form @submit.prevent="UpdateSupplier">
                  <div class="form-group">
                    <label style="padding: 5px"
                      >الاسم <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="supplier.name"
                      aria-describedby="validateName"
                      required
                    />
                    <div v-if(errors.name) id="validateName" class="invalid-feedback">
                      {{errors.name}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label style="padding: 5px"
                      >البريد الالكتروني <span class="star">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      v-model="supplier.email"
                      aria-describedby="validateEmail"
                      required
                    />
                    <div v-if(errors.email) id="validateEmail" class="invalid-feedback">
                      {{errors.email}}
                    </div>
                  </div>
                  <CRow>
                    <CCol sm="12">
                      <div class="form-group">
                        <label style="padding: 5px"
                          >الهاتف <span class="star">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="supplier.mobile"
                          placeholder=" 00974XXXXXXXX ادخل رقم الهاتف"
                          aria-describedby="validateMobile"
                          required
                        />
                        <div v-if(errors.mobile) id="validateMobile" class="invalid-feedback">
                          {{errors.mobile}}
                        </div>
                      </div>
                    </CCol> </CRow
                  ><br />
                  <div class="form-group">
                    <label style="padding: 5px"
                      >الوصف</label
                    >
                    <textarea
                      type="text"
                      class="form-control"
                      v-model="supplier.description"
                    ></textarea>
                  </div>
                  <br/>
                  <button type="submit" class="btn btn-success">
                    تحديث المورد
                  </button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "EditSupplier",
  data() {
    return {
      supplier: [],
      mobile: [],
      email: [],
      role: "",
      errors: []
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}suppliers/${this.$route.params.id}`)
      .then((response) => {
        if (response.data.error) {
          this.$router.back();
        }
        this.supplier = response.data.data;
      });
  },
  methods: {
    UpdateSupplier() {
      let currentObj = this;
      let formData = new FormData();
      formData.append("name", this.supplier.name);
      formData.append("_method", "PUT");
      formData.append("mobile", this.supplier.mobile);
      formData.append("email", this.supplier.email);
      if (this.supplier.description) formData.append("description", this.supplier.description);
      
      this.$http
        .post(
          `${process.env.VUE_APP_URL}suppliers/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" })
            currentObj.$router.push({ path: "/suppliers" })
          } else {
            this.errors = response.data.data
            console.log(this.errors.mobile)
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.invalid-feedback {
  display: inherit;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>